var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clientSiteData && _vm.$can('read', 'clients'))?_c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"9"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_vm._v(" Client Account ")]),_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":"/apps/cameras/list"}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}})],1),_c('b-breadcrumb-item',{attrs:{"to":"/apps/clients/list"}},[_vm._v(" Client Account ")]),_c('b-breadcrumb-item',{attrs:{"to":'/apps/clients/view/'+_vm.clientSiteData.clientname}},[_vm._v(" "+_vm._s(_vm.clientSiteData.clientname)+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" View Site: "+_vm._s(_vm.clientSiteData.sitename)+" ")])],1)],1)])],1)],1)],1),_c('b-alert',{attrs:{"variant":"danger","show":_vm.clientSiteData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error fetching client sites data ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No client sites found with this client site name. ")])]),(_vm.clientSiteData)?[_c('b-row',[_c('b-col',{attrs:{"cols":"24","xl":"12","lg":"12","md":"12"}},[_c('client-sites-view-client-sites-info-card',{attrs:{"client-site-data":_vm.clientSiteData}})],1),_c('b-col',{attrs:{"cols":"24","xl":"12","lg":"12","md":"12"}},[_c('client-sites-view-client-sites-info-notes',{attrs:{"client-site-data":_vm.clientSiteData}})],1),(typeof _vm.clientSiteData.geofence !== 'undefined' &&
          typeof _vm.clientSiteData.geofence.lat !== 'undefined' &&
          typeof _vm.clientSiteData.geofence.lon !== 'undefined' &&
          typeof _vm.clientSiteData.geofence.distance !== 'undefined' &&
          _vm.clientSiteData.geofence.lat !== '' &&
          _vm.clientSiteData.geofence.lon !== '' &&
          _vm.clientSiteData.geofence.distance !== ''
        )?_c('b-col',{attrs:{"cols":"24","xl":"12","lg":"12","md":"12"}},[_c('client-sites-view-client-sites-info-Map',{attrs:{"client-site-data":_vm.clientSiteData}})],1):_vm._e()],1)]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }