<template>
  <b-card v-if="$can('read', 'clients')">

    <b-row>

      <!-- Client Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        lg="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Client Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ clientSiteData.sitename }}
              </h4>
            </div>
            <div
              v-if="$can('update', 'clients')"
              class="d-flex flex-wrap"
            >
              <b-button
                :to="{ name: 'apps-client-sites-edit', params: { clientname: clientSiteData.clientname, sitename: clientSiteData.sitename, clientSiteData: clientSiteData } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="4"
        lg="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Site Name</span>
            </th>
            <td class="pb-50">
              {{ clientSiteData.sitename }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Client</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.client_display_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </th>
            <td>
              {{ clientSiteData.phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.status }}
            </td>
          </tr>
          <tr>
            <span><br></span>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            {{ clientSiteData.address }}
          </tr>
          <tr v-if="clientSiteData.addressUnit && clientSiteData.addressUnit !==''">
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.addressUnit }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.city }}, {{ clientSiteData.province }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.country }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" />
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.postal }}
            </td>
          </tr>
          <tr>
            <span><br></span>
          </tr>
          <tr>
            <th class="pb-50 align-top">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Services</span>
            </th>
            <td class="pb-50 text-capitalize">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <ul class="ml-0 pl-1">
                <li
                  v-for="item in servicesList"
                  :key="item.id"
                >
                  {{ item.value }}
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol,
  },
  props: {
    clientSiteData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      servicesList: [],
    }
  },
  setup(props) {
    let theAddress = ''
    theAddress = `${props.clientSiteData.address}`
    if (props.clientSiteData.addressUnit !== null && props.clientSiteData.addressUnit !== '') {
      theAddress = `${theAddress}, Unit ${props.clientSiteData.addressUnit}<br>`
    } else {
      theAddress = `${theAddress}<br>`
    }
    if (props.clientSiteData.province !== null && props.clientSiteData.province !== '') {
      theAddress = `${theAddress}${props.clientSiteData.province}`
      if (props.clientSiteData.country !== null && props.clientSiteData.country !== '') {
        theAddress = `${theAddress}, `
      }
    }
    if (props.clientSiteData.country !== null && props.clientSiteData.country !== '') {
      theAddress = `${theAddress}${props.clientSiteData.country}`
    }
    if (props.clientSiteData.postal !== null && props.clientSiteData.postal !== '') {
      theAddress = `${theAddress}<br>${props.clientSiteData.postal}`
    }

    return {
      theAddress,
    }
  },
  created() {
    if (typeof this.clientSiteData.services === 'undefined') {
      this.clientSiteData.services = {}
    }
    if (typeof this.clientSiteData.services.guard !== 'undefined' && this.clientSiteData.services.guard === true) {
      this.servicesList.push({ value: 'Guard Services', id: 1 })
    }
    if (typeof this.clientSiteData.services.videomonitoring !== 'undefined' && this.clientSiteData.services.videomonitoring === true) {
      this.servicesList.push({ value: 'Video Monitoring Services', id: 2 })
    }
    if (typeof this.clientSiteData.services.guardtour !== 'undefined' && this.clientSiteData.services.guardtour === true) {
      this.servicesList.push({ value: 'Guard Tour Services', id: 3 })
    }
    if (typeof this.clientSiteData.services.tenant !== 'undefined' && this.clientSiteData.services.tenant === true) {
      this.servicesList.push({ value: 'Tenant Services', id: 4 })
    }
    if (typeof this.clientSiteData.services.privateinvestigation !== 'undefined' && this.clientSiteData.services.privateinvestigation === true) {
      this.servicesList.push({ value: 'Private Investigation Services', id: 5 })
    }
    console.log(this.servicesList)
  },
}
</script>

<style>

</style>
